import { Editor, Element, Node } from 'slate'
import {
  Admonition,
  Title as AdmonitionTitle,
  Caption,
  Commentary,
  Exercise,
  Figure,
  Problem,
  Proof,
  Rule,
  RuleExample,
  Solution,
  Table,
} from 'cnx-designer'
import { ReferenceTarget, ReferenceTargetType } from '../../store/types'

/**
 * Checks if node is a referencable block which may contian referencable children.
 */
const isReferencableParent = (node: Node) => {
  return Exercise.isExercise(node)
    || Rule.isRule(node)
}

/**
 * Checks if node is a children of referencable block.
 */
const isReferencableChildren = (node: Node) => {
  return Commentary.isCommentary(node)
    || Proof.isProof(node)
    || Problem.isProblem(node)
    || RuleExample.isRuleExample(node)
    || Solution.isSolution(node)
}

type Referencable = Admonition | Exercise | Figure | Rule | Table

const isReferencable = (node: Node): node is Referencable => {
  if (
    Admonition.isAdmonition(node)
    || Exercise.isExercise(node)
    || Figure.isFigure(node)
    || Rule.isRule(node)
    || Table.isTable(node)
  ) return true
  return false
}

export const getRefTargets = (
  editor: Editor,
  nodes?: Node[],
  parentCounters?: [string, number][],
  matchReferencableChildren = false,
) => {
  const counters: Map<string, number> = new Map()
  const refTargets: ReferenceTarget[] = []

  for (const [child] of Node.nodes({ children: nodes || editor.children })) {
    if (!('id' in child) || typeof child.id !== 'string') continue

    if (!Element.isElement(child)) continue
    if (!isReferencable(child)) continue
    if (matchReferencableChildren && !isReferencableChildren(child)) continue
    if (!matchReferencableChildren && isReferencableChildren(child)) continue

    const type: ReferenceTargetType = Admonition.isAdmonition(child) ? child.kind : child.type

    if (counters.has(type)) {
      counters.set(type, counters.get(type)! + 1)
    } else {
      counters.set(type, 1)
    }

    const refCounters: [string, number][] = [
      [type, counters.get(type) || 0],
      ...parentCounters || [],
    ]

    let children: ReferenceTarget[] = []

    if (isReferencableParent(child)) {
      children = getRefTargets(editor, child.children, refCounters, true)
    }

    refTargets.push({
      id: child.id,
      type,
      description: getDescriptionForNode(child),
      children,
      counters: refCounters,
    })
  }

  return refTargets
}

const getDescriptionForNode = (node: Node) => {
  if (
    Admonition.isAdmonition(node)
    || Commentary.isCommentary(node)
    || Solution.isSolution(node)
  ) {
    if (AdmonitionTitle.isTitle(node.children[0])) {
      return node.children[0]
    }
    return Node.string(node.children[0])
  } else if (Figure.isFigure(node)) {
    if (Caption.isCaption(node.children[node.children.length-1])) {
      return Node.string(node.children[node.children.length-1])
    }
  } else if (
    Exercise.isExercise(node)
    || Rule.isRule(node)
  ) {
    return Node.string((node.children[0] as Element).children[0])
  }

  return Node.string(node)
}
