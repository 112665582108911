import { AxiosResponse } from 'axios'
import axios from '../config/axios'
import { chunk } from '../helpers'
import APICache, { APICacheMap } from './apicache'
import Book from './book'
import Module from './module'
import Pagination from './pagination'
import ProcessVersion from './processversion'
import { ProcessID, ProcessSingleStep, SlotPermission } from './process'
import { UserData } from './user'
import { TeamID } from './team'
import { removeBookPartsWhichDoesNotMatchDrafts, sortDraftIdsByBookParts } from './draftUtils'
import { elevated, extend } from './utils'
import { BookPart } from '.'
import { VersionID } from './processversion'

/**
 * Draft data as returned by the API.
 *
 * @type {Object}
 */
export type DraftData = {
  module: string
  title: string
  language: string
  license: string | null
  permissions?: SlotPermission[]
  step?: ProcessSingleStep
  team: TeamID
  validation_messages: string | null
}

export type Diff = {
  title?: string,
  language?: string,
  license?: string | null,
}

export type DraftAndBookPart = {
  module: string
  book: string | null
  part: number | null
}

export type GroupedDraftsNoBook = {
  // If we do not specify these fields then we will not be able to
  // do a check for GroupedDrafts like `data.book?.team` because TS will throw an error that
  // `book` does not exists on GroupedDraftsNoBook
  book: undefined
  parts: undefined
  draftIds: string[][]
}

export type GroupedDraftsWithBook = {
  book: Book
  parts: BookPart[]
  draftIds: string[][]
}

export type GroupedDrafts = {
  totalDrafts: number
  data: (GroupedDraftsWithBook | GroupedDraftsNoBook)[]
}

export type DraftFile = {
  name: string
  mime: string
}

/**
 * Result data for POST /api/v1/drafts/:id/advance
 */
export type AdvanceResult = {
  code: AdvanceCode,
  draft?: Draft, // if code is draft:process:advanced
  module?: Module, // if code is draft:process:finished
}

/**
 * draft:process:advanced if draft was advanced to the next step.
 * draft:process:finished if action has ended the process.
 */
export type AdvanceCode = 'draft:process:advanced' | 'draft:process:finished'

/**
 * Details about the process this draft follows.
 */
export type ProcessDetails = {
  process: ProcessVersion,
  slots: SlotDetails[],
}

export type SlotDetails = {
  id: number,
  name: string,
  roles: number[],
  user: Omit<UserData, 'teams'> | null,
}

export const DRAFT_CONTENT_EDITING_PERMISSIONS: SlotPermission[] = [
  'accept-changes',
  'edit',
  'propose-changes',
]

export default class Draft {
  /**
   * Fetch module by ID.
   */
  static load(module: Module | string, fromCache = true): Promise<Draft> {
    const id = module instanceof Module ? module.id : module
    return APICache.getOrSetNested(
      ['Drafts', id],
      fromCache,
      async () => new Draft((await axios.get(`drafts/${id}`)).data),
    )
  }

  static async loadMultiple(ids: string[], fromCache = true): Promise<Draft[]> {
    const cachedDrafts: Draft[] = []
    const filteredIds = ids.filter(id => {
      const cachedDraft = APICache.getNested(['Drafts', id])
      if (cachedDraft && fromCache) {
        cachedDrafts.push(cachedDraft)
        return false
      }
      return true
    })

    const data: DraftData[] = filteredIds.length
      ? (await axios.get(`drafts?id=${filteredIds.join(',')}`)).data
      : []
    const newDrafts = data.map(d => new Draft(d))

    APICache.update('Drafts', {
      ...newDrafts.reduce((obj, draft) => ({ ...obj, [draft.module]: draft }), {}),
    })

    return cachedDrafts.concat(newDrafts)
  }

  /**
   * Fetch all drafts current user has access to.
   */
  static async all(): Promise<DraftAndBookPart[]> {
    return (await axios.get('drafts')).data
  }

  /**
   * Return users drafts grouped in book structure.
   */
  static async groupedInBooks(drafts?: DraftAndBookPart[]): Promise<GroupedDrafts> {
    const allDrafts = drafts || await this.all()

    const groupedByBook: Map<string, Set<string>> = new Map()
    for (const { module, book } of allDrafts) {
      groupedByBook.set(
        book || 'empty',
        (groupedByBook.get(book || 'empty') || new Set()).add(module))
    }

    // Fetch data about book parts and split draft ids into parts of 32
    const groupedData: GroupedDrafts = {
      totalDrafts: 0,
      data: [],
    }

    for (const [bookId, draftIds] of groupedByBook.entries()) {
      groupedData.totalDrafts += draftIds.size

      if (bookId === 'empty') {
        groupedData.data.push({
          book: undefined,
          parts: undefined,
          draftIds: chunk(Array.from(groupedByBook.get('empty')!), 32),
        })
      } else {
        const book = await Book.load(bookId)
        const parts = (await book.parts()).parts || []
        const trimmedParts = parts
          .map(part => removeBookPartsWhichDoesNotMatchDrafts(part, draftIds))
          .filter(p => Boolean(p)) as BookPart[]
        const ids = chunk(Array.from(draftIds).sort(sortDraftIdsByBookParts(trimmedParts)), 32)
        groupedData.data.push({ book, parts: trimmedParts, draftIds: ids })
      }
    }

    return groupedData
  }

  /**
   * Assign user to a slot.
   *
   * This function requires editing-process:manage permission.
   */
  static async assignUser(draftId: string, slot: number, user: number): Promise<void> {
    await axios.put(`drafts/${draftId}/process/slots/${slot}`, {
      user,
    })
    APICache.invalidate('Drafts', 'Drafts/Processes')
  }

  /**
   * Unassign user from a slot.
   *
   * This function requires editing-process:manage permission.
   */
  static async unassignUser(draftId: string, slot: number): Promise<void> {
    await axios.put(`/drafts/${draftId}/process/slots/${slot}`, {
      user: null,
    })
    APICache.invalidate('Drafts', 'Drafts/Processes')
  }

  /**
   * Get details about the process this draft follows.
   *
   * This function requires editing-process:manage permission.
   */
  static details(draftId: string, fromCache = true): Promise<ProcessDetails> {
    return APICache.getOrSetNested(
      ['Drafts/Processes', draftId],
      fromCache,
      async () => {
        const data = (await elevated(() => axios.get(`drafts/${draftId}/process`))).data
        const process = new ProcessVersion(data)
        APICache.updateNested(
          ['Processes/Versions', process.id[0].toString()],
          { [data.id[1].toString()]: process },
        )
        return { process, slots: data.slots }
      },
    )
  }

  /**
   * ID of the module of which this is a draft.
   */
  module!: string

  /**
   * Title of the module as of this draft.
   */
  title!: string

  /**
   * Language of the module as of this draft.
   */
  language!: string

  /**
   * URL of the license under which this draft will be distributed
   */
  license!: string | null

  /**
   * List of slot permissions current user has at this step.
   */
  permissions?: SlotPermission[]

  /**
   * Information about the step this draft is currently in.
   */
  step?: ProcessSingleStep

  /**
   * All books ids in which this draft occurs.
   */
  books!: Pagination<string>

  /**
   * ID of team for which this draft belongs.
   */
  team!: TeamID

  /**
   * Contains a message describing why the document failed validation,
   * or `null` if it passed validation.
   */
  validation_messages!: string | null

  constructor(data: DraftData) {
    extend(this, data)
    this.books = new Pagination(
      `/modules/${data.module}/books`,
      undefined,
      undefined,
    )
  }

  /**
   * Advance this draft to the next step.
   *
   * @param target and @param slot together must name one of the links returned in
   * GET /api/v1/drafts/:id.
   */
  async advance(data: { target: number, slot: number }): Promise<AdvanceResult> {
    const res = (await axios.post(`drafts/${this.module}/advance`, data)).data
    await this.books.loadAll()
    APICache.invalidate(
      ['Modules', this.module],
      ['Drafts', this.module],
      ...this.books.items().map(id => ['Books/Parts', id] as [keyof APICacheMap, string]))
    return res
  }

  /**
   * Fetch list of files in this draft. This list does not include index.cnxml.
   */
  files(fromCache = true): Promise<DraftFile[]> {
    return APICache.getOrSetNested(
      ['Drafts/Files', this.module],
      fromCache,
      async () => (await axios.get(`drafts/${this.module}/files`)).data,
    )
  }

  /**
   * Fetch contents of a file.
   */
  async read(name: string): Promise<string> {
    // Do not cache this.
    return (await axios.get(`drafts/${this.module}/files/${name}`)).data
  }

  /**
   * Update title of this draft.
   * Requires 'edit' or 'editing-process:manage' permission.
   */
  async update(diff: Diff) {
    await axios.put(`drafts/${this.module}`, diff)
    this.title = diff.title ?? this.title
    this.language = diff.language ?? this.language
    this.license = diff.license !== undefined ? diff.license : this.license
  }

  /**
   * Cancel process for this draft.
   *
   * All changes will be lost.
   */
  async cancelProcess(): Promise<void> {
    await axios.delete(`drafts/${this.module}`)
    await this.books.loadAll()
    APICache.invalidate(
      ['Modules', this.module],
      ['Drafts', this.module],
      ...this.books.items().map(id => ['Books/Parts', id] as [keyof APICacheMap, string]))
  }

  /**
   * Change process for this draft.
   */
  async changeProcess(
    process: number,
    step: number,
    slotMapping: Map<number, number | null>,
  ): Promise<void> {
    await axios.put(`drafts/${this.module}/process`, {
      process,
      step,
      slots: Array.from(slotMapping),
    })
    await this.books.loadAll()
    APICache.invalidate(
      ['Drafts', this.module],
      ['Drafts/Processes', this.module],
      ...this.books.items().map(book => ['Books/Parts', book] as ['Books/Parts', string]),
    )
  }

  /**
   * Write index.cnxml.
   */
  writeCNXML(text: string) {
    return axios.put(`drafts/${this.module}/files/index.cnxml`, text)
  }

  /**
   * Write a file.
   */
  writeFile(file: File): Promise<AxiosResponse> {
    return axios.put(`drafts/${this.module}/files/${file.name}`, file)
  }

  /**
   * Remove a file.
   */
  removeFile(file: DraftFile) {
    return axios.delete(`drafts/${this.module}/files/${file.name}`)
  }

  /**
   * Replace a file.
   */
  replaceFile(file: DraftFile, newFile: File) {
    return axios.put(`drafts/${this.module}/files/${file.name}`, newFile)
  }

  /**
   * Check if at least one of provided permissions have overlap
   * with user's permissions in the current step.
   */
  hasPermission(permissions: SlotPermission[]) {
    if (!this.permissions) return false
    for (const p of permissions) {
      if (this.permissions.includes(p)) return true
    }
    return false
  }
}
