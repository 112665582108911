import * as Sentry from '@sentry/react'
import * as React from 'react'
import { Book, Draft, Module, Process, ProcessVersion, Role, Team, User } from '..'
import Pagination from '../pagination'
import { DraftAndBookPart, ProcessDetails } from '../draft'
import { TeamID } from '../team'
import BookPart from '../bookpart'

const PROMISES: Map<string, Promise<any>> = new Map()

const cachedPromise = <T extends any>(promise: () => Promise<T>, name: string): Promise<T> => {
  const cached = PROMISES.get(name) as Promise<T> || promise()
  PROMISES.set(name, cached)
  return cached.finally(() => PROMISES.delete(name))
}

// This is temporarly created hook until we add filtering and searching utilities to the server
const useLoadAll = <T extends any>(
  pagination: Pagination<T> | undefined,
  cachingName: string,
  sentryErrorMsg: string,
  deps: unknown[] = [],
): [T[], boolean, Error | null] => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [data, setData] = React.useState<T[]>([])

  React.useEffect(() => {
    let mounted = true

    if (pagination) {
      setIsLoading(true)
      cachedPromise(() => pagination.loadAll(), cachingName)
        .then(() => {
          if (mounted) setData(pagination.items())
        })
        .catch(e => {
          Sentry.captureMessage(sentryErrorMsg, 'warning')
          if (mounted) {
            setError(e)
            setData([])
          }
        })
        .finally(() => {
          if (mounted) setIsLoading(false)
        })
    }

    return () => {
      mounted = false
      setData([])
      setError(null)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, cachingName, sentryErrorMsg, ...deps])

  return [data, isLoading, error]
}

const useLoad = <T extends any>(
  promise: (() => Promise<T>) | undefined,
  cachingName: string,
  sentryErrorMsg: string,
  deps: unknown[] = [],
): [value: T | undefined, isLoading: boolean, error: Error | null] => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [data, setData] = React.useState<T | undefined>(undefined)

  React.useEffect(() => {
    let mounted = true

    if (promise) {
      cachedPromise(promise, cachingName)
        .then(res => {
          if (mounted) setData(res)
        })
        .catch(e => {
          Sentry.captureMessage(sentryErrorMsg, 'warning')
          if (mounted) {
            setError(e)
            setData(undefined)
          }
        })
        .finally(() => {
          if (mounted) setIsLoading(false)
        })
    }
    return () => {
      mounted = false
      setData(undefined)
      setError(null)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promise, cachingName, sentryErrorMsg, ...deps])

  return [data, isLoading, error]
}

export const useLoadModule = (id?: string) => {
  const promise = React.useMemo(() => id ? () => Module.load(id) : undefined, [id])
  const data = useLoad(
    promise,
    `useLoadModule-${id}`,
    `Couldn't load module with id: ${id}`)
  return data
}

export const useLoadSpecificBookPart = (book: Book | string, partId: string) => {
  const bookId = book instanceof Book ? book.id : book
  const promise = React.useMemo(() => () => BookPart.load(bookId, partId), [bookId, partId])
  const data = useLoad(
    promise,
    `useLoadSpecificBookPart-${bookId}-${partId}`,
    `Couldn't load specific book part for book: ${bookId} and part ${partId}`)
  return data
}

export const useLoadDraft = (id?: string) => {
  const promise = React.useMemo(() => id ? () => Draft.load(id) : undefined, [id])
  const data = useLoad(
    promise,
    `useLoadDraft-${id}`,
    `Couldn't load draft with id: ${id}`)
  return data
}

export const useLoadBookPart = (book: Book) => {
  const promise = React.useCallback(() => book.parts(), [book])
  const data = useLoad(
    promise,
    `useLoadBookPart-${book.id}`,
    `Couldn't load bookpart for book ${book.id}`)
  return data
}

export const useLoadUser = (id?: number | string) => {
  const promise = React.useMemo(() => id ? () => User.load(id) : undefined, [id])
  const data = useLoad(
    promise,
    `useLoadUser-${id}`,
    `Couldn't load user with id: ${id}`)
  return data
}

export const useLoadUsers = (ids: number[]) => {
  const idsDep = React.useMemo(() => ids.join(','), [ids])
  const promise = React.useCallback(
    () => Promise.all(ids.map(id => User.load(id))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsDep])
  const data = useLoad(
    promise,
    `useLoadUsers-${idsDep}`,
    `Couldn't load users for passed ids: ${idsDep}`)
  return data
}

// This is temporarly created hook until we add filtering and searching utilities to the server
export const useLoadAllUsers = (deps: any[] = []) => {
  const data = useLoadAll(
    User.pagination(), 'useLoadAllTeams', `Couldn't load list of all users.`, deps)
  return data
}

export const useLoadTeam = (
  id?: number,
): [value: Team | undefined, isLoading: boolean, error: Error | null] => {
  const promise = React.useMemo(() => id ? () => Team.load(id) : undefined, [id])
  const data = useLoad(
    promise,
    `useLoadTeam-${id}`,
    `Couldn't load team with id: ${id}`)
  return data
}

export const useLoadProcess = (id?: number) => {
  const promise = React.useMemo(() => id ? () => Process.load(id) : undefined, [id])
  const data = useLoad(
    promise,
    `useLoadProcess-${id}`,
    `Couldn't load process with id: ${id}`)
  return data
}

export const useLoadProcessVersion = (process?: number, version?: number) => {
  const promise = React.useMemo(
    () => process != null && version != null
      ? () => ProcessVersion.load(process, version)
      : undefined,
    [process, version],
  )
  const data = useLoad(
    promise,
    `useLoadProcessVersion-[${process}, ${version}]`,
    `Couldn't load version ${version} of process with id ${process}`)
  return data
}

export const useLoadLatestStructure = (process: Process | ProcessVersion) => {
  const promise = React.useMemo(() => () => process.structure(), [process])
  const data = useLoad(
    promise,
    `useLoadStructure-${process.id}`,
    `Couldn't load structure for process with id: ${process.id}`)
  return data
}

export const useLoadStructure = (process: ProcessVersion) => {
  const promise = React.useMemo(() => () => process.structure(), [process])
  const data = useLoad(
    promise,
    `useLoadStructure-${process.id}`,
    `Couldn't load structure for process with id: ${process.id}`)
  return data
}

export const useLoadAllLoginMethods = () => {
  const promise = React.useCallback(() => User.allLoginMethods(), [])
  const data = useLoad(
    promise,
    `useLoadAllLoginMethods`,
    `Couldn't load all login methods.`)
  return data
}

export const useLoadUserLoginMethods = (user: User | null | undefined) => {
  const promise = React.useMemo(() => user ? () => user.loginMethods() : undefined, [user])
  const data = useLoad(
    promise,
    `useLoadUserLoginMethods-${user?.id}`,
    `Couldn't load login methods for user: ${user?.id}`)
  return data
}

export const useLoadPage = (pagination: Pagination<any>, number?: number) => {
  const promise = React.useCallback(() => pagination.loadPage(number || 1), [pagination, number])
  const data = useLoad(
    promise,
    `useLoadPage-${number || 1}`,
    `Couldn't load page ${number || 1} for ${pagination.endpoint}`)
  return data
}

// This is temporarly created hook until we add filtering and searching utilities to the server
export const useLoadAllTeams = (deps: any[] = []) => {
  const data = useLoadAll(
    Team.pagination(), 'useLoadAllTeams', `Couldn't load list of all teams.`, deps)
  return data
}

// This is temporarly created hook until we add filtering and searching utilities to the server
export const useLoadAllTeamRoles = (team?: Team) => {
  const data = useLoadAll(
    team?.roles,
    `useLoadAllTeamRoles-${team?.id}`,
    `Couldn't load list of all roles for team ${team?.id}.`,
    [team])
  return data
}

export const useLoadDrafts = (ids: string[]) => {
  const idsDep = React.useMemo(() => ids.join(','), [ids])
  const promise = React.useCallback(
    () => Promise.all(ids.map(id => Draft.load(id))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsDep])
  const data = useLoad(
    promise,
    `useLoadDrafts-${idsDep}`,
    `Couldn't load drafts for passed ids: ${idsDep}`)
  return data
}

export const useLoadDraftsDetails = (drafts: Draft[]) => {
  const idsDep = React.useMemo(() => drafts.map(d => d.module).join(','), [drafts])
  const promise = React.useCallback(
    () => Promise.all(drafts.map(async d => [d, await Draft.details(d.module)] as [Draft, ProcessDetails])),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idsDep])
  const data = useLoad(
    promise,
    `useLoadDraftsDetails-${idsDep}`,
    `Couldn't load details for drafts ids: ${idsDep}`)
  return data
}

export const useLoadDraftsGroupedInBooks = (drafts: DraftAndBookPart[]) => {
  const promise = React.useCallback(() => Draft.groupedInBooks(drafts), [drafts])
  const data = useLoad(
    promise,
    `useLoadDraftsGroupedInBooks-${drafts.map(d => d.module)}`,
    `Couldn't group drafts in books`)
  return data
}

// This is temporarly created hook until we add filtering and searching utilities to the server
export const useLoadAllProcesses = (deps: any[] = []) => {
  const data = useLoadAll(
    Process.pagination(), 'useLoadAllProcesses', `Couldn't load list of all processes.`, deps)
  return data
}

// This is temporarly created hook until we add filtering and searching utilities to the server
export const useLoadAllBooks = (deps: any[] = []) => {
  const data = useLoadAll(
    Book.pagination(), 'useLoadAllBooks', `Couldn't load list of all books.`, deps)
  return data
}

export const useLoadRoles = (team: TeamID, roleIds: number[]) => {
  const promise = React.useCallback(
    () => Promise.all(roleIds.map(id => Role.load(id, team))),
    [team, roleIds])
  const data = useLoad(
    promise,
    `useLoadRoles-${roleIds.join(',')}`,
    `Couldn't load list of all roles with ids ${roleIds.join(',')} for team ${team}.`)
  return data
}
