import * as React from 'react'
import { Localized } from '@fluent/react'
import { Draft, Module, Process, ProcessVersion, Team, User } from '../../api'
import { ProcessDetails } from '../../api/draft'
import { ProcessSlot, ProcessStructure } from '../../api/process'
import { SlotId, UserId } from '../BeginProcess'
import Dialog from '../../components/ui/Dialog'
import Load from '../../components/Load'
import UsersList from '../../containers/UsersList'
import SlotInfo from './SlotInfo'

import './index.css'

interface UpdateSlotsProps {
  team: Team
  drafts: [ProcessVersion, ProcessStructure, [Draft, ProcessDetails][]][]
}

async function loader(
  { team, process, modules }: {
    team: Team | number
    process: Process | ProcessVersion
    modules: (Module | string)[]
  },
): Promise<{
  team: Team
  drafts: [ProcessVersion, ProcessStructure, [Draft, ProcessDetails][]][]
}> {
  const realTeam = team instanceof Team ? team : await Team.load(team)

  const details = await Promise.all(modules.map(m => {
    const id = m instanceof Module ? m.id : m
    return Promise.all([Draft.load(id), Draft.details(id)])
  }))

  const processes: [ProcessVersion, ProcessStructure][] = process instanceof ProcessVersion
    ? [[process, await process.structure()] as [ProcessVersion, ProcessStructure]]
    : await Promise.all(Array.from(
      new Map(details.map(([, p]) => [p.process.id.toString(), p.process])),
      async ([_, p]) => [p, await p.structure()] as [ProcessVersion, ProcessStructure],
    ))

  const drafts = processes.map(([p, s]) => [
    p,
    s,
    details.filter(d => p.id[0] === d[1].process.id[0] && p.id[1] === d[1].process.id[1]),
  ] as [ProcessVersion, ProcessStructure, [Draft, ProcessDetails][]])

  return { team: realTeam, drafts }
}

function UpdateSlots({ team, drafts }: UpdateSlotsProps) {
  return (
    <div className="update-slots">
      {drafts.map(([process, structure, drafts], pinx) => <>
        {pinx > 0 && <hr/>}
        <UpdateSlotsSingle team={team} process={process} structure={structure} drafts={drafts} />
      </>)}
    </div>
  )
}

type UpdateSlotsSingleProps = {
  team: Team
  process: ProcessVersion
  structure: ProcessStructure
  drafts: [Draft, ProcessDetails][]
}

function UpdateSlotsSingle({ team, process, structure, drafts }: UpdateSlotsSingleProps) {
  const [slots, setSlots] = React.useState<Map<SlotId, UserId[]>>(new Map())
  const [currentSlot, setCurrentSlot] = React.useState<ProcessSlot | null>(null)

  React.useEffect(() => {
    // set initial users in slots
    const slots = new Map<SlotId, UserId[]>()
    for (const [, details] of drafts) {
      for (const slot of details.slots) {
        if (slot.user && (!slots.has(slot.id) || !slots.get(slot.id)!.includes(slot.user.id))) {
          slots.set(slot.id, [...slots.get(slot.id) || [], slot.user.id])
        }
      }
    }
    setSlots(slots)
  }, [drafts, setSlots])

  const toggleUserForSlot = React.useCallback(async (slot: ProcessSlot, user: User | null = null) => {
    const promises = []

    for (const [draft] of drafts) {
      promises.push(user
        ? Draft.assignUser(draft.module, slot.id, user.id)
        : Draft.unassignUser(draft.module, slot.id))
    }

    await Promise.all(promises)

    setSlots(state => {
      const slots = new Map(state)
      if (user) {
        slots.set(slot.id, [user.id])
      } else {
        slots.delete(slot.id)
      }
      return slots
    })
    setCurrentSlot(null)
  }, [drafts, setSlots, setCurrentSlot])

  const showAssignUserDialog = React.useCallback((slot: ProcessSlot) => {
    setCurrentSlot(slot)
  }, [setCurrentSlot])

  return <>
    <h3>
      <Localized id="update-slots-title">
        Manage slots assignments for drafts:
      </Localized>
    </h3>
    <ul>
      {drafts.map(d => <li key={d[0].module}>{d[0].title}</li>)}
    </ul>
    {
      structure.slots.map(s => (
        <SlotInfo
          key={s.id}
          team={team.id}
          slot={s}
          slots={slots}
          onAssignUser={showAssignUserDialog}
          onUnassignUser={toggleUserForSlot}
        />
      ))
    }
    {
      currentSlot != null && (
        <Dialog
          l10nId="update-slots-assign-user-title"
          vars={{
            slot: currentSlot.name,
            roles: currentSlot.roles.length ? currentSlot.roles.join(', ') : 'undefined',
          }}
          placeholder="Select user with [roles] for current slot."
          size="medium"
          onClose={() => setCurrentSlot(null)}
        >
          <UsersList
            allowedRoles={currentSlot.roles}
            teams={[team]}
            onUserClick={user => toggleUserForSlot(currentSlot, user)}
          />
        </Dialog>
      )
    }
  </>
}

export default Load(loader)<{
  team: Team | number
  process: Process | ProcessVersion
}>(UpdateSlots)
