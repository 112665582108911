/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://74245313f86c1ebe3683306585762fad@sentry.naukosfera.com/4'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = 'bcbe35b97dc9dfcde5d8bfd8ded34e0f83e7fc8d'
