import * as React from 'react'
import { Localized } from '@fluent/react'
import { Process, ProcessVersion, Team } from '../../api'
import { useLoadLatestStructure, useLoadTeam } from '../../api/hooks'
import Spinner from '../../components/Spinner'
import Slot from './Slot'
import Step from './Step'

import './index.css'

interface ProcessPreviewProps {
  team: Team | number
  process: Process | ProcessVersion
}

const ProcessPreview = ({ team: teamId, process }: ProcessPreviewProps) => {
  const [team, isLoadingTeam] = useLoadTeam(teamId instanceof Team ? teamId.id : teamId)
  const [structure, isLoadingStructure] = useLoadLatestStructure(process)
  const version = process instanceof Process ? process.version : process.id[1]

  if (isLoadingTeam || isLoadingStructure) {
    return <div className="process-preview">
      <Spinner />
    </div>
  }

  if (!team || !structure) return null

  return (
    <div className="process-preview">
      <h2>
        <Localized id="process-preview-title" vars={{ name: structure.name, version }}>
          Process name:
        </Localized>
      </h2>
      <div className="process-preview__slots">
        <h3>
          <Localized id="process-preview-slots-list">
            List of slots:
          </Localized>
        </h3>
        <ul>
          {
            structure.slots.map(s => <li key={s.id}><Slot team={team.id} slot={s} /></li>)
          }
        </ul>
      </div>
      <div className="process-preview__steps">
        <h3>
          <Localized id="process-preview-steps-list">
            List of steps:
          </Localized>
        </h3>
        <ul>
          {
            structure.steps.map(s => (
              <li key={s.id}>
                <Step
                  step={s}
                  slots={structure.slots}
                  steps={structure.steps}
                />
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default ProcessPreview
