import * as React from 'react'
import * as CNXML from 'slate-cnxml'
import { Editor, Node, Text } from 'slate'
import { AxiosResponse } from 'axios'
// import { CnxEditor } from 'cnx-designer'
// import { BaseEditor } from 'slate-suggestions'
import axios from '../config/axios'
import { withPluginsDeserialize } from '../screens/app/Draft/plugins'
import { serializeMediaText } from '../screens/app/Draft/plugins/MediaText'
import { serializeHighlight } from '../screens/app/Draft/plugins/Highlights'
import { serialize as serializeMath } from '../screens/app/Draft/plugins/Math'
import { serializeSourceElement } from '../screens/app/Draft/plugins/SourceElements'
import { serializeSuggestion, serializeSuggestionText } from '../screens/app/Draft/plugins/Suggestions'
import Draft, { DraftFile } from './draft'

/**
 * Exception thrown by methods of {@link Storage} on API errors.
 */
export class APIError extends Error {
  _response: AxiosResponse

  constructor(response: AxiosResponse) {
    super(`${response.status} ${response.statusText}`)
    this._response = response
  }

  /**
   * Whole response for the failed request.
   */
  get response() {
    return this._response
  }

  /**
   * Status code for the failed request.
   */
  get status() {
    return this.response.status
  }

  /**
   * Status text for the failed request.
   */
  get statusText() {
    return this.response.statusText
  }
}

/**
 * A file, as understood by {@link Storage}.
 */
export type FileDescription = {
  name: string,
  mime: string,
}

export default class Storage {
  id!: string

  url!: string

  title!: string

  language: string = 'en'

  files!: FileDescription[]

  tag!: string

  document: Node[] | null = null

  documentClasses: string[] | undefined = []

  /**
   * Load a document by ID.
   *
   * Retrieves basic information about a document and prepares
   * a {@link Storage} for later work.
   *
   * @return {Storage}
   */
  static async load(id: string) {
    const self = new Storage()

    self.id = id
    self.url = '/api/v1/drafts/' + id
    self.document = null

    const draft = await Draft.load(id)
    const files = await draft.files()

    self.title = draft.title
    self.files = files

    return self
  }

  /**
   * Read the document.
   */
  async read(): Promise<Index> {
    // Do not cache this
    const index = await axios.get(`drafts/${this.id}/files/index.cnxml`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
    const filesList = await axios.get(`drafts/${this.id}/files`)
    this.tag = index.headers.etag
    return new Index(this.tag, await index.data, await filesList.data)
  }

  /**
   * Write the document
   */
  async write(editor: Editor, overwrite: boolean = false) {
    try {
      const text = this.serialize(editor)

      const rsp = await axios.put(
        `drafts/${this.id}/files/index.cnxml`,
        text,
        overwrite
          ?
          undefined
          :
          {
            headers: {
              'If-Match': this.tag,
            },
          },
      )

      this.document = editor.children
      this.tag = rsp.headers.etag
    } catch (e) {
      if (e.response) {
        throw new APIError(e.response)
      } else {
        throw e
      }
    }
  }

  /**
   * Write a file.
   */
  async writeFile(file: File) {
    try {
      await axios.put(`drafts/${this.id}/files/${file.name}`, file)
      this.files.push({ name: file.name, mime: file.type })
    } catch (e) {
      throw new APIError(e.response)
    }
  }

  /** Set classes for the <document> element */
  setDocumentClasses(classes: string[] | undefined) {
    this.documentClasses = classes
  }

  /**
   * Set language for this document to given value.
   * It will be used to save cnxml with correct value.
   *
   * @param {string} code ISO language code
   */
  setLanguage(code: string) {
    this.language = code
  }

  mediaUrl = (name: string) => `${this.url}/files/${name}`

  mimeFromName = (name: string) => {
    const file = this.files.find(file => file.name === name)
    if (file) return file.mime
    return 'unknown'
  }

  private mediaMime: CNXML.MediaMimeFunction = media => this.mimeFromName(media.src)

  private serializeElement: CNXML.PartialSerializer<any, any> = (node, attrs, children, ctx) => {
    return serializeMediaText(node, attrs, children, ctx)
      || serializeHighlight(node, attrs, children, ctx)
      || serializeSourceElement(node, attrs, children, ctx)
      || serializeSuggestion(node, attrs, children)
      || serializeMath(node, attrs, children)
  }

  private serializeText: CNXML.PartialSerializer<Text> = (node, attrs, children, ctx) => {
    return serializeSuggestionText(node, attrs as unknown as CNXML.CommonAttrs, children)
  }

  serialize(document: Editor) {
    return CNXML.serialize(
      document,
      {
        classes: this.documentClasses,
        language: this.language,
        // Module id has to start with a letter
        moduleId: 'U' + this.id,
        title: this.title,
        version: '0.7',
        content: document.children,
      },
      {
        format: 'xml',
        mediaMime: this.mediaMime,
        serializeElement: this.serializeElement,
        serializeText: this.serializeText,
      },
    )
  }
}

export class Index {
  version: string

  withEditor: <T extends CNXML.DeserializingEditor>(editor: T) => T

  content: string

  constructor(version: string, content: string, filesList: DraftFile[]) {
    this.version = version
    this.withEditor = withPluginsDeserialize(filesList)
    this.content = content
  }

  deserialize() {
    return CNXML.deserialize(this.withEditor, this.content)
  }
}

export const StorageContext = React.createContext(new Storage())
