import * as React from 'react'
import * as Counters from 'react-counters'
import {
  Caption, ColumnSet, Table, TableCell, TableFooter, TableGroup, TableHeader, TableRow,
} from 'cnx-designer'
import { Editor, Node, Path, Point, Transforms } from 'slate'
import { RenderElementProps } from 'slate-react'
import { TABLE } from '../../counters'
import { pointMax, pointMin } from '../../utils'

const ColumnsContext = React.createContext<ColumnSet>({
  columns: [],
  columnNames: {},
  spans: {},
})

export interface RenderTableProps extends RenderElementProps {
  element: Table
}

export function TableComp({ element, attributes, children }: RenderTableProps) {
  Counters.useCounter(attributes.ref, { increment: [TABLE] })

  return (
    <div
      className="adr-table"
      id={element.id as string | undefined}
      {...attributes}
    >
      {children}
    </div>
  )
}

export interface RenderTableGroupProps extends RenderElementProps {
  element: TableGroup
}

export function TableGroupComp({ element, attributes, children }: RenderTableGroupProps) {
  // FIXME: we need to recreate Table.columns here, as we don't have access to
  // element's path

  const set = React.useMemo(() => {
    const set: ColumnSet = {
      columns: element.columns,
      columnNames: {},
      spans: {},
    }

    for (const span of element.spans) {
      set.spans[span.name] = span
    }

    for (let i = 0; i < set.columns.length; ++i) {
      const column = set.columns[i]
      if (column.name != null) {
        set.columnNames[column.name] = i
      }
    }

    return set
  }, [element.columns, element.spans])

  return (
    <table className="adr-group" {...attributes}>
      <tbody>
        <ColumnsContext.Provider value={set}>
          {children}
        </ColumnsContext.Provider>
      </tbody>
    </table>
  )
}

function useHeaderFooterColumnSet(element: TableHeader | TableFooter): ColumnSet {
  const group = React.useContext(ColumnsContext)

  return React.useMemo(() => {
    if (element.columns == null) return group

    const set: ColumnSet = {
      columns: element.columns,
      columnNames: {},
      spans: {},
    }

    for (let i = 0; i < set.columns.length; ++i) {
      const column = set.columns[i]
      if (column.name != null) {
        set.columnNames[column.name] = i
      }
    }

    return set
  }, [group, element.columns])
}

export interface RenderTableHeaderProps extends RenderElementProps {
  element: TableHeader
}

export function TableHeaderComp({ element, attributes, children }: RenderTableHeaderProps) {
  const set = useHeaderFooterColumnSet(element)

  // TODO: mark as header
  return (
    <ColumnsContext.Provider value={set}>
      {children}
    </ColumnsContext.Provider>
  )
}

export interface RenderTableFooterProps extends RenderElementProps {
  element: TableFooter
}

export function TableFooterComp({ element, attributes, children }: RenderTableFooterProps) {
  const set = useHeaderFooterColumnSet(element)

  // TODO: mark as footer
  return (
    <ColumnsContext.Provider value={set}>
      {children}
    </ColumnsContext.Provider>
  )
}

export interface RenderTableRowProps extends RenderElementProps {
  element: TableRow
}

export function TableRowComp({ element, attributes, children }: RenderTableRowProps) {
  return (
    <tr {...attributes}>
      {children}
    </tr>
  )
}

export interface RenderTableCellProps extends RenderElementProps {
  element: TableCell
}

export function TableCellComp({ element, attributes, children }: RenderTableCellProps) {
  const columns = React.useContext(ColumnsContext)
  let colspan

  if (element.column == null || 'column' in element.column) {
    // Either implicit positioning or column-based positioning. In both cases
    // columns before this will position this correctly
  } else if ('start' in element.column) {
    const start = columns.columnNames[element.column.start]
    const end = columns.columnNames[element.column.end]
    colspan = end - start + 1
  } else {
    const start = columns.columnNames[columns.spans[element.column.span].start]
    const end = columns.columnNames[columns.spans[element.column.span].end]
    colspan = end - start + 1
  }

  return (
    <td rowSpan={element.rows} colSpan={colspan} {...attributes}>
      {children}
    </td>
  )
}
